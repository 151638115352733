import type React from 'react';
import { forwardRef, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '@mui/material/Input';
import type { InputBaseProps } from '@mui/material/InputBase';
import OutlinedInput from '@mui/material/OutlinedInput';

import { SignupFormCtx } from '@/components/shared/signup-form-v2/SignupFormV2';
import TdFilledInput from '@/components/uikit/Input/PrivateComponents/TdFilledInput';
import { FormInputCtx } from '@/components/uikit/Input/TdInput';
import { useAppDispatch, useAppSelector } from '@/hooks';
import type { SignUpFormData } from '@/types/signup.types';
import trackingUtils from '@/utils/tracking.util';

import { signupFormEngaged } from '../../../redux/features/trackingSlice';

type ControlledInputProps = {
  disabledOnBlurValidation?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  variety: keyof SignUpFormData;
  expVariant?: string;
} & InputBaseProps

const ControlledInput: React.FC<ControlledInputProps> = forwardRef(function ControlledInput(
  {
    disabledOnBlurValidation = false,
    variety,
    variant = 'filled',
    ...props
  },
  ref,
) {
  const dispatch = useAppDispatch();
  const trackingState = useAppSelector(state => state.tracking);
  const { setValue, trigger, getValues } = useFormContext<SignUpFormData>();
  const { setTouchedFields } = useContext(FormInputCtx);
  const trackCtx = useContext(SignupFormCtx);

  async function isFieldValid(fieldName: keyof SignUpFormData, value: string | undefined): Promise<boolean> {
    const isFieldEmpty = !value;
    return isFieldEmpty ? true : await trigger(fieldName);
  }

  async function handleChangeOnInput(
    { target }: { target: HTMLInputElement | HTMLTextAreaElement },
    fieldName: keyof SignUpFormData,
  ): Promise<void> {
    // We need to set the value manually to the registered field
    setValue(fieldName, target.value);

    // Don't Trigger react form validation when target value is undefined or empty
    const isValid = await isFieldValid(fieldName, target.value);

    if (isValid) {
      setTouchedFields(prevState => ({
        ...prevState,
        [fieldName]: false,
      }));
    }
  }

  async function handleBlurOnInput(
    _e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof SignUpFormData,
  ): Promise<void> {
    // Don't Trigger react form validation when target value is undefined or empty
    const isValid = await isFieldValid(fieldName, _e.target?.value);

    if (!isValid && !disabledOnBlurValidation) {
      setTouchedFields(prevState => ({
        ...prevState,
        [fieldName]: true,
      }));
    }
  }

  function handleFocusOnInput(
    fieldName: keyof SignUpFormData,
    evt?: React.FocusEvent,
  ) {
    let hasAutoFocus = false;

    if (evt?.target?.getAttribute('has-auto-focus') === 'true') {
      hasAutoFocus = true;
      evt?.target.removeAttribute('has-auto-focus');
    }

    trackOnFocus(fieldName, hasAutoFocus);
  }

  function trackOnFocus(fieldName: keyof SignUpFormData, hasAutoFocus: boolean) {
    if (hasAutoFocus) return;

    const { signupForm, signupTrigger } = trackingState;

    if (signupForm.engagementLocations.includes(signupTrigger)) return;

    trackingUtils.trackEvent({
      event: trackCtx.engagementEvent,
      category: trackCtx.eventCategory,
      properties: {
        name: trackCtx.engagementName,
        email: getValues('email'),
        namefield: fieldName === 'name',
        companyemailfield: fieldName === 'email',
        passwordfield: fieldName === 'password',
        engagementlocation: signupTrigger,
      },
    });

    dispatch(signupFormEngaged(signupTrigger));
  }

  const listeners: InputBaseProps = {
    onFocus: (event) => handleFocusOnInput(variety, event),
    onPaste: () => handleFocusOnInput(variety),
    onChange: e => handleChangeOnInput(e, variety),
    onBlur: e => handleBlurOnInput(e, variety),
  };

  switch (variant) {
    case 'standard':
      return <Input ref={ ref } { ...props } { ...listeners } />;
    case 'filled':
      return <TdFilledInput ref={ ref } { ...props } { ...listeners } />;
    case 'outlined':
      return <OutlinedInput ref={ ref } { ...props } { ...listeners } />;
  }
});

export default ControlledInput;
