import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';
import type { RootState } from 'src/redux/store';

import type { LinkButtonProps } from '@/components/uikit/link-button/LinkButton';
import LinkButtonCustom from '@/components/uikit/link-button-custom/LinkButtonCustom';
import type { ButtonCustomStoryblok, IconStoryblok } from '@/sb-types';


type ButtonBlokProps = {
  blok: SbBlokData<ButtonCustomStoryblok>;
}

type UserStyles = Pick<ButtonCustomStoryblok, 'marginObj' | 'customColor' | 'bgColor' | 'paddingObj' | 'sizeObj' | 'borderRadiusObj'>

const propsToForward: (keyof LinkButtonProps)[] = [
  'noHover',
  'variant',
  'textUnderline',
  'color',
];

const IconSvg = styled('span')<Omit<IconStoryblok, 'iconSvg'>>(({ iconColor, marginObj, sizeObj, theme }) => ({
  display: 'inline-flex',

  '& svg': {
    width: '100%',
    height: 'auto',

    '& path': {
      fill: 'currentColor',
    },
  },

  ...marginObj,
  ...sizeObj,
  ...iconColor,
  ...theme.utils.objStyleBreakpoints(marginObj, sizeObj, iconColor),
}));

const StyledLinkButton = styled(LinkButtonCustom, {
  shouldForwardProp: (prop) => isPropValid(prop) || propsToForward.includes(prop as keyof LinkButtonProps),
})<UserStyles>(({ marginObj, theme, sizeObj, borderRadiusObj, paddingObj, variant, color }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  fontWeight: 600,
  padding: '15px',
  fontSize: '20px',
  letterSpacing: '0',

  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '&.MuiButton-root:active': {
    backgroundColor: theme.palette[color || 'primaryCustom']['dark'],
    border: `2px solid ${ theme.palette[color || 'primaryCustom']['dark'] }`,
  },
  ...(variant === 'outlined' && {
    '&.MuiButton-root:active': {
      border: `2px solid ${ theme.palette[color || 'primaryCustom']['dark'] }`,
      backgroundColor: theme.palette[color || 'primaryCustom']['dark'],
      color: '#ffffff',
    },
  }),
  ...(variant === 'text' && {
    '&.MuiButton-root:active': {
      backgroundColor: theme.palette[color || 'primaryCustom']['dark'],
      color: '#ffffff',
    },
  }),

  ...marginObj,
  ...sizeObj,
  ...borderRadiusObj,
  ...paddingObj,
  ...theme.utils.objStyleBreakpoints(marginObj, sizeObj, borderRadiusObj, paddingObj),
}));

export const ButtonCustomBlok: FC<ButtonBlokProps> = ({ blok }) => {
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const pageInfo = useSelector((state: RootState) => state.tracking.page);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsPreviewMode(window.location.hostname.includes('localhost') || window.location.hostname.includes('timedoctortest'));
    }
  }, []);

  let link = isPreviewMode ? blok.urlTest?.url || blok.link?.cached_url : blok.link?.url || blok.link?.cached_url;

  // Replace page with the current page name in the link
  if (link.includes('homecta=pg-page')) link = link.replace(/\bpage\b/g, pageInfo.toLowerCase());

  const linkUrl = blok.link?.cached_url.startsWith('http') || link.includes('#')
    ? link // External link, use as-is
    : `/${ link.replace(/^\/+/, '') }`; // Internal link, ensure it starts with a single /

  return (
    <StyledLinkButton
      href={ linkUrl || '#' }
      color={ blok.colorButton || 'primaryCustom' }
      noHover={ !blok.hover } textUnderline={ blok.hasUnderline }
      { ...storyblokEditable(blok) }
      { ...blok }
    >
      { blok.startIconSvg && (
        <IconSvg style={{ 'padding': '0 5px 0 0' }} dangerouslySetInnerHTML={{ __html: blok.startIconSvg }} />
      ) }
      { blok.label }
      { blok.endIconSvg && (
        <IconSvg style={{ 'padding': '0 0 0 5px' }} dangerouslySetInnerHTML={{ __html: blok.endIconSvg }} />
      ) }
    </StyledLinkButton>
  )
}
