import type { ThemeOptions } from '@mui/material';

export const palette: ThemeOptions['palette'] = {
  primary: {
    main: '#0066FF',
    light: '#3385FF',
    dark: '#0052CC',
    contrastText: '#fff',
  },
  secondary: {
    main: '#04D464',
    light: '#36DD83',
    dark: '#03AA50',
    contrastText: '#fff',
  },
  saffron: {
    main: '#f7b82f',
    contrastText: '#fff',
  },
  blue: {
    main: '#0066FF',
    light: '#b1b7f0',
    contrastText: '#fff',
  },
  neutral: {
    main: '#6d6f78',
    light: '#edeef2',
    lighter: '#a4a7b2',
    lightest: '#d9dbe9',
    lightDark: '#8c8f99',
    dark: '#2A221F',
    contrastText: '#fff',
  },
  white: {
    main: '#fff',
    light: '#fff',
    dark: '#fff',
    contrastText: '#2c2e3c',
  },
  info: {
    main: '#B3AEAB',
    light: '#C2BEBC',
    lightDark: '#717e95',
    lighter: '#a4a7b2',
    contrastText: '#fff',
    dark: '#8F8B89',
  },
  error: {
    main: '#d32f2f',
    light: '#f22f42',
  },
  primaryCustom: {
    main: '#0066FF',
    light: '#3385FF',
    dark: '#0052CC',
    contrastText: '#fff',
  },
  secondaryCustom: {
    main: '#04D464',
    light: '#36DD83',
    dark: '#03AA50',
    contrastText: '#fff',
  },
  black: {
    main: '#342B27',
    light: '#5D5552',
    contrastText: '#fff',
    dark: '#2A221F',
  },
};
