import type React from 'react';
import { createContext } from 'react';
import type { InputBaseProps as MuiInputBaseProps } from '@mui/material/InputBase';

import { TdInputEmail } from '@/components/uikit/Input/PrivateComponents/TdInputEmail';
import { TdInputName } from '@/components/uikit/Input/PrivateComponents/TdInputName';
import { TdInputPassword } from '@/components/uikit/Input/PrivateComponents/TdInputPassword';
import type { AsyncEmailErr, FormId, InputContainerType, InputData, SignUpFormTouchedFields } from '@/types/signup.types';

type FormControlAdditionalProps = {
  rounded?: boolean;
  labelMode?: 'label' | 'placeholder';
  errorDisplay?: 'text' | 'tooltip';
}

export type TdFormInputProps = InputData & TdInputProps

export type TdInputProps = {
  inputId: string;
  label: string;
  variant?: 'standard' | 'outlined' | 'filled';
  containerType?: InputContainerType;
  fullWidth?: boolean;
  expVariant?: string;
} & MuiInputBaseProps & FormControlAdditionalProps

type FormInputCtxData = {
  touchedFields: SignUpFormTouchedFields;
  setTouchedFields: React.Dispatch<React.SetStateAction<SignUpFormTouchedFields>>;
  setAsyncEmailErr: React.Dispatch<React.SetStateAction<AsyncEmailErr| undefined>>;
  asyncEmailErr: AsyncEmailErr | undefined;
  formId: FormId;
}

export const FormInputCtx = createContext<FormInputCtxData>({
  touchedFields: {
    name: false,
    email: false,
    password: false,
    companySize: false,
    phoneNumber: false,
  },
  setTouchedFields: () => ({}),
  setAsyncEmailErr: () => ({}),
  asyncEmailErr: undefined,
  formId: 'register',
});

export const FormInputProvider = FormInputCtx.Provider;

export const TdInput: React.FC<TdFormInputProps> = ({ variety, ...props }) => {
  switch (variety) {
    case 'email':
      return <TdInputEmail { ...props } />;
    case 'name':
      return <TdInputName { ...props } />;
    case 'password':
      return <TdInputPassword { ...props } />;
    default:
      return null;
  }
};
