import { useEffect, useState } from 'react';
import Botpoison from '@botpoison/browser';

import { useBotFilter } from '../../context/BotFilterContext'; // Import the custom hook to access context

// Function to initialize Botpoison and get the solution
const initBotPoison = async (setSolution: (solution: string) => void) => {
  if (!process.env.NEXT_PUBLIC_TD_BOTPOISON_KEY) return;

  const botpoison = new Botpoison({
    publicKey: process.env.NEXT_PUBLIC_TD_BOTPOISON_KEY,
  });

  const { solution } = await botpoison.challenge();

  setSolution(solution); // Store the solution value
};

// Generate a random field name for the hidden checkbox
const generateRandomFieldName = () =>
  `rand-bcheck-${ Math.random().toString(36).substring(2) }`;

interface BotFilterCheckboxProps {
  fieldName?: string;
  checked?: boolean;
}

export const BotFilter: React.FC<BotFilterCheckboxProps> = ({ fieldName }) => {
  const { setIsChecked, setCheckboxFieldName } = useBotFilter();
  const [checkboxFieldName] = useState(fieldName || generateRandomFieldName());
  const [botpoisonSolution, setBotpoisonSolution] = useState<string | null>(null);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Update the state when the checkbox is clicked
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    // Initialize Botpoison and set the solution value when the component mounts
    initBotPoison(setBotpoisonSolution);

    // Set the checkboxFieldName in the global context
    setCheckboxFieldName(checkboxFieldName);
  }, [checkboxFieldName, setBotpoisonSolution, setCheckboxFieldName]);

  return (
    <>
      { /* Hidden checkbox with dynamic field name */ }
      <input
        style={{
          position: 'absolute',
          'zIndex': '-1',
        }}
        type="checkbox"
        name={ checkboxFieldName }
        onChange={ handleCheckboxChange }
      />

      { /* Hidden input field for Botpoison solution */ }
      { botpoisonSolution && (
        <input type="hidden" name="_botpoison" value={ botpoisonSolution } />
      ) }
    </>
  );
};
