import type { FC } from 'react';
import { css } from '@emotion/react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import type { Theme } from '@mui/material/styles';

import RenderIf from '@/shared/render-if/RenderIf';
import type { PasswordRule } from '@/types/signup.types';
import { PasswordRuleManager } from '@/utils/password-rule-strategy.util';

import { theme } from '../../../../theme';

type iconColorType = 'error' | 'primary' | undefined;

type PasswordRulesItemProps = {
  rule: PasswordRule;
  iconColor: iconColorType;
  watchValue: string;
  touchedFields: {
    password: boolean;
  };
  expVariant?: string;
};

type PasswordRulesProps = {
  watchValue: string;
  iconColor: iconColorType;
  touchedFields: {
    password: boolean;
  };
  expVariant?: string;
};

// Add CSS style to remove bullets from the list
const styles = {
  List: css`
    list-style-type: none;
    padding: 15px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
  `,
  ListItem: (theme: Theme) => css`
    color: var(--list-color, ${ theme.palette.info.lighter });
    display: flex;
    align-items: center;
    font-weight: ${ theme.typography.inputText.fontWeight };
    font-size: ${ theme.typography.inputText.fontSize };
    line-height: ${ theme.typography.inputText.lineHeight };
    &[data-not-met="true"] {
      --list-color: ${ theme.palette.error.light };
    }

    &[data-is-met="true"] {
      --list-color: ${ '#342B27' };
    }
  `,
  ListItemSpan: css`
    margin-left: 4px;
  `,
};

const PasswordRulesItem: FC<PasswordRulesItemProps> = ({
  rule,
  iconColor,
  watchValue,
  touchedFields,
}) => {

  const isRuleMet = rule.validate(watchValue || '');
  const iconStyle = { color: '#04D464' };
  return (
    <li
      data-is-met={ isRuleMet }
      data-not-met={ iconColor && touchedFields.password }
      css={ [
        styles.ListItem(theme),
      ] }
    >
      <div css={ styles.ListItem }>
        <RenderIf condition={ touchedFields.password && !isRuleMet }>
          <HighlightOffIcon />
        </RenderIf>
        <RenderIf condition={ !touchedFields.password && !isRuleMet }>
          <CircleOutlined />
        </RenderIf>
        <RenderIf condition={ isRuleMet }>
          <CheckCircle style={ iconStyle } />
        </RenderIf>
        <span css={ styles.ListItemSpan }>{ rule.label }</span>
      </div>
    </li>
  );
};

export const PasswordRules: FC<PasswordRulesProps> = ({
  watchValue,
  iconColor,
  touchedFields,
  expVariant,
}) => (
  <ul css={ styles.List }>
    { PasswordRuleManager.rules().map((strategy, index) => (
      <PasswordRulesItem
        key={ index }
        rule={ strategy }
        iconColor={ iconColor }
        touchedFields={ touchedFields }
        watchValue={ watchValue }
        expVariant={ expVariant }
      />
    )) }
  </ul>
);

