import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { Theme } from '@mui/material/styles';


const formStyles = css`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  margin: 0 auto;

  & .hs_recaptcha, & .hs-recaptcha {
    display: none;
  }

  & .input {
    margin: 0 !important;
  }

  & .hs-input {
    width: 100% !important;
  }
`;

const fieldsetStyles = css`
  max-width: 100% !important;

  &.form-columns-2 {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;

    & > * {
      flex: 1 1 280px;
    }

    & .field {
      float: none;
      width: 100%;
    }
  }
`;

const labelStyles = (theme: Theme) => css`
  ${ theme.typography.body3 as unknown as SerializedStyles };

  display: block;
  margin-bottom: 8px;
  min-height: 20px;
  color: ${ theme.palette.info.main };

  & .hs-form-required {
    margin-left: 4px;
    color: ${ theme.palette.error.light };
  }
`;

const inputStyles = (theme: Theme) => css`
  ${ theme.typography.body3 as unknown as SerializedStyles };

  padding: 12px 20px;
  width: 100%;

  border-radius: 10px;
  border: 1px solid ${ theme.palette.neutral.lightest };
  background: #fff;
  color: ${ theme.palette.info.light };

  &.invalid, &.error {
    border-color: ${ theme.palette.error.light };
  }

  &::placeholder {
    color: ${ theme.palette.info.lightDark };
  }

  &:focus {
    outline: none;
    border-color: ${ theme.palette.neutral.lightDark };
  }
`;

const selectStyles = (theme: Theme) => css`
  ${ inputStyles(theme) };

  padding-right: 44px;

  appearance: none;
  background: url('/_static/svg/keyboard_arrow_down.svg') no-repeat right 20px center #fff;
`;

const submitBtnStyles = (theme: Theme) => css`
  ${ theme.typography.cta as unknown as SerializedStyles };

  position: relative;
  padding: 12px 24px;
  width: 100%;

  border-radius: 10px 0 10px 10px;
  background: ${ theme.palette.secondary.main };
  color: ${ theme.palette.secondary.contrastText };
  cursor: pointer;
`;

const errorsListStyles = (theme: Theme) => css`
  list-style: none;
  padding: 8px 0 0;

  & li {
    & label {
      ${ theme.typography.body3 as unknown as SerializedStyles };

      color: ${ theme.palette.error.light };
      margin: 0 !important;
    }
  }
`;

export const styles = {
  FormWrapper: (theme: Theme) => css`
    margin: 0 auto;
    width: 570px;
    max-width: 100%;

    //Temporary solution for the iframe height

    & .hs-form-iframe {
      height: 690px !important;

      @media (max-width: 521px) {
        height: 720px !important;
      }

      @media (max-width: 480px) {
        height: 880px !important;
      }

      @media (max-width: 406px) {
        height: 950px !important;
      }

      @media (max-width: 318px) {
        height: 1020px !important;
      }
    }

    & form {
      ${ formStyles };

      & fieldset {
        ${ fieldsetStyles };

        & label {
          ${ labelStyles(theme) };
        }

        & input {
          ${ inputStyles(theme) };
        }

        & select {
          ${ selectStyles(theme) };
        }

        & .hs-error-msgs {
          ${ errorsListStyles(theme) };
        }
      }

      & .hs-submit, & .hs_submit {
        position: relative;

        & input[type='submit'] {
          ${ submitBtnStyles(theme) };
        }
      }
    }
  `,
  SubmitLoader: (theme: Theme) => css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px 0 10px 10px;
    background: ${ theme.palette.secondary.main };

    & > * {
      color: #fff !important;
    }
  `,
};
