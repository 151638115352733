import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import type { WithCssProp } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';
import { RevenueHero } from '@revenuehero/sdk-react'
import Script from 'next/script';

import trackingUtils from '@/utils/tracking.util';

import { styles } from './HubspotForm.styles';


const excludedFields = ['g-recaptcha-response', 'hs_context'];

type HubspotFormProps = WithCssProp<{
  formCategory: string;
  trackEventName: string;

  className?: string;
  withLoader?: boolean;
}> & Omit<HubspotFormConfig, 'target' | 'portalId'>;

export const HubspotForm: FC<HubspotFormProps> = ({
  formId,
  formCategory,
  className,
  onFormSubmitted,
  withLoader,
  redirectUrl,
  trackEventName,
}) => {
  const [submitBtnElm, setSubmitBtnElm] = useState<Element | null>(null);
  const didLoadScript = useRef(false);

  const handleFormSubmit: HubspotFormCallback = useCallback((formElement) => {
    const filterFn = (elm: HTMLInputElement) => !!elm.value && !!elm.name && !excludedFields.includes(elm.name);
    const formValues = ([...formElement.elements] as HTMLInputElement[])
      .filter(filterFn)
      .reduce((prevValue: Record<string, unknown>, currentValue) => ({
        ...prevValue,
        [currentValue.name]: currentValue.value,
      }), {});

    trackingUtils.trackEvent({
      event: trackEventName,
      category: formCategory,
      properties: {
        ...formValues,
      },
    });
  }, [formCategory, trackEventName]);

  const handleHubspotScriptLoaded = useCallback(() => {
    didLoadScript.current = true;

    window.hbspt?.forms?.create({
      portalId: `${ process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID }`,
      formId,
      target: '#hubspotForm',
      redirectUrl,
      onBeforeFormSubmit: (formElm) => {
        const submitBtn = formElm.querySelector('.hs-submit');
        if (submitBtn) setSubmitBtnElm(submitBtn);
      },
      onFormSubmit: handleFormSubmit,
      onFormSubmitted,
    });
  }, [formId, handleFormSubmit, onFormSubmitted, redirectUrl]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (didLoadScript.current || window.hbspt?.forms?.create) {
        handleHubspotScriptLoaded();
      }
    }
  }, [handleHubspotScriptLoaded]);

  return (
    <>
      <Script
        type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"
        onLoad={ handleHubspotScriptLoaded } />

      <div id="hubspotForm" css={ styles.FormWrapper } className={ className }>
        { withLoader && <CircularProgress /> }
        <RevenueHero
          showLoader
          enabled
          routerId={ process.env.NEXT_PUBLIC_BOOK_DEMO_ROUTER_ID as string }
          formId={ formId } />
      </div>
      {
        submitBtnElm !== null && createPortal(
          <div css={ styles.SubmitLoader }><CircularProgress size={ 24 } /></div>,
          submitBtnElm,
        )
      }
    </>
  );
};
