import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import type { TabsProps } from '@mui/material/Tabs';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { a11yProps, BasicTab, BasicTabs } from '@/components/uikit/basic-tabs/BasicTabs';
import { FlexBoxV2 } from '@/components/uikit/flex-box/FlexBox';
import { LinkTrackingCtx } from '@/components/uikit/link/TdLink';
import TabPanel from '@/components/uikit/tab-panel/TabPanel';
import type { TabsStoryblok, TabStoryblok } from '@/sb-types';
import type { LinkTrackCtxData } from '@/types/tracking.types';
import trackingUtils from '@/utils/tracking.util';


type TabsBlokProps = {
  blok: SbBlokData<TabsStoryblok>
}

type TabBlokProps = {
  blok: SbBlokData<TabStoryblok>
}

export const TabBlok: FC<TabBlokProps> = ({ blok }) => (
  <>
    {
      blok.content.map((nestedBlok) => (<StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
    }
  </>
);

const StyledFlexBox = styled(FlexBoxV2)<TabsStoryblok>(({ theme, flexConfig, marginObj, paddingObj }) => ({
  ...flexConfig,
  ...marginObj,
  ...paddingObj,

  ...theme.utils.objStyleBreakpoints(flexConfig, marginObj, paddingObj),
}));


export const TabsBlok: FC<TabsBlokProps> = ({ blok }) => {
  const [value, setValue] = useState(0);
  const inheritedTrackingCtx = useContext(LinkTrackingCtx);

  const handleTabChange: TabsProps['onChange'] = (_event, newValue) => {
    setValue(newValue);
  };

  const handleTabClick = (tabPanelId: string) => {
    const trackingCtx: LinkTrackCtxData = {
      ...inheritedTrackingCtx,
      linkName: tabPanelId,
      linkType: 'tab-blue',
    };

    trackingUtils.linkTracking(null, trackingCtx);
  };

  useEffect(() => {
    // Parse URL to handle both fragment and query params
    const url = new URL(window.location.href);
    const [fragment = '', queryString = ''] = url.hash.substring(1).split('?');

    // Handle scroll if fragment exists
    if (fragment === 'tabs') {
      const element = document.getElementById('tabs');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    // Handle tab selection if id parameter exists
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      const tabId = urlParams.get('id');
      if (tabId) {
        const tabIndex = blok.tabs.findIndex((tab) => tab.panelId === tabId);
        if (tabIndex !== -1) {
          setValue(tabIndex);
        }
      }
    }
  }, [blok.tabs]);

  return (
    <>
      <StyledFlexBox { ...blok } { ...storyblokEditable(blok) }>
        <BasicTabs
          value={ value } onChange={ handleTabChange }
          variant="scrollable"
          scrollButtons="auto"
        >
          {
            blok.tabs.map((tab, i) => (
              <BasicTab
                key={ tab._uid } label={ tab.label }
                onClick={ () => handleTabClick(tab.panelId) }
                { ...a11yProps(i, tab.panelId) }
              />
            ))
          }
        </BasicTabs>
      </StyledFlexBox>
      {
        blok.tabs.map((tabBlok, i) => (
          <TabPanel
            key={ `${ blok.panelId }-tabpanel-${ i }` }
            index={ i } idPrefix="section-1"
            value={ value }
            { ...storyblokEditable(tabBlok) }
          >
            <StoryblokComponent blok={ tabBlok } key={ tabBlok._uid } />
          </TabPanel>
        ))
      }
    </>
  );
};
