import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import type { FeatureDefinition, Result } from '@growthbook/growthbook-react';
import { GrowthBook, GrowthBookProvider as GrowthBookBaseProvider } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';

import { trackExperiment, uuid } from '@/utils/tracking.util';

import type { ExperimentProps } from '../../types';

type GrowthBookProviderProps = {
  features: Record<string, FeatureDefinition>;
  children?: ReactNode
}

// Create a GrowthBook instance
const growthBook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_KEY,
  decryptionKey: process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY,
  enableDevMode: process.env.NEXT_PUBLIC_VERCEL_ENV === 'development',
  subscribeToChanges: true,
  trackingCallback: (_exp, { value }: Result<ExperimentProps>) => {
    trackExperiment(value);
  },
});

export const GrowthBookProvider: FC<GrowthBookProviderProps> = ({ features, children }) => {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const queryParams = router.query as Record<string, string>;

      growthBook.setFeatures(features || {});
      growthBook.setAttributes({
        anonymous_id: uuid(),
        browser: navigator.userAgent,
        url_path: router.pathname,
        home_cta: queryParams['homecta'],
        td_internal: queryParams['td_internal'],
      });

      // Load most recent features async and enable refreshing features in realtime (subscribeToChanges: true)
      growthBook.loadFeatures();
    }
  }, [router, features]);

  return <GrowthBookBaseProvider growthbook={ growthBook }>{ children }</GrowthBookBaseProvider>;
};
