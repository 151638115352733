import type React from 'react';

type TabPanelProps = {
  children?: React.ReactNode;
  idPrefix: string;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, idPrefix, ...other }) => (
  <div
    id={ `${ idPrefix }-tabpanel-${ index }` }
    role="tabpanel"
    hidden={ value !== index }
    aria-labelledby={ `${ idPrefix }-tab-${ index }` }
    { ...other }
  >
    { value === index && (
      <>{ children }</>
    ) }
  </div>
);

export default TabPanel;
