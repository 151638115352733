import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

// Create a context for the checkbox field name and checked state
interface BotFilterContextType {
  checkboxFieldName: string | null;
  setCheckboxFieldName: (fieldName: string) => void;
  isChecked: boolean;
  setIsChecked: (checked: boolean) => void;
}

const BotFilterContext = createContext<BotFilterContextType | undefined>(undefined);

// Create a provider component
export const BotFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [checkboxFieldName, setCheckboxFieldName] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState(false); // Track the checkbox state (checked or not)

  return (
    <BotFilterContext.Provider value={{ checkboxFieldName, setCheckboxFieldName, isChecked, setIsChecked }}>
      { children }
    </BotFilterContext.Provider>
  );
};

// Custom hook to use the BotFilterContext
export const useBotFilter = () => {
  const context = useContext(BotFilterContext);
  if (!context) {
    throw new Error('useBotFilter must be used within a BotFilterProvider');
  }
  return context;
};
