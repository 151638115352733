import type { FC } from 'react';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useFeature } from '@growthbook/growthbook-react';
import { FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import type { SelectInputProps } from '@mui/material/Select/SelectInput';
import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';

import { FlexBoxV2 } from '@/components/uikit/flex-box/FlexBox';
import { FormInputCtx } from '@/components/uikit/Input';
import styles from '@/shared/signup-form-v2/SignupFormV2.module.scss';
import type { SignUpFormData } from '@/types/signup.types';
import trackingUtils from '@/utils/tracking.util';


const selectFieldStyles = (isSelected: boolean, isPlaceholderSelected: boolean) => ({
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#d9dbe9',
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B3AEAB',
    borderWidth: 'thin',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B3AEAB',
    borderWidth: 'thin',
  },
  '.MuiButtonBase-root-MuiMenuItem-root': {
    color: 'red',
  },
  '.MuiOutlinedInput-input.MuiSelect-select': {
    color: isPlaceholderSelected ? '#B3AEAB !important' : 'black !important',
  },

  ...(isSelected && {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#27c26c',
      borderWidth: 'thin',
    },

  }),
});

const nameInForm: keyof Pick<SignUpFormData, 'companySize'> = 'companySize';

export const companySizes = [
  { value: '1-25', label: '1-25' },
  { value: '26-50', label: '26-50' },
  { value: '51-100', label: '51-100' },
  { value: '101-500', label: '101-500' },
  { value: '501-1000', label: '501-1000' },
  { value: '1001+', label: '1001 or more' },
];

export const newCompanySizes = [
  { value: '1-10', label: '1-10' },
  { value: 'n11_50', label: '11-50' },
  { value: 'n51_200', label: '51-200' },
  { value: 'n201_500', label: '201-500' },
  { value: '501-1000', label: '501-1000' },
  { value: '1001+', label: '1001 or more' },
];

const inputLabelStyle = (theme: Theme): SxProps => ({
  width: '100%',
  textAlign: 'left',
  fontSize: theme.typography.body3.fontSize,
  fontStyle: theme.typography.body3.fontStyle,
  lineHeight: theme.typography.body3.lineHeight,
  fontWeight: theme.typography.body3.fontWeight,
  color: '#342B27',
  marginBottom: '5px',
});

export const CompanySize: FC = () => {
  const theme = useTheme();
  const { touchedFields } = useContext(FormInputCtx);
  const { watch, getFieldState, setValue, trigger } = useFormContext<SignUpFormData>();
  const { companySize: isTouched } = touchedFields;
  const companySize = watch('companySize');
  const { error } = getFieldState('companySize');
  const isPlaceholderSelected = companySize === '';

  const customStyles = selectFieldStyles(companySize !== '', isPlaceholderSelected);
  const showNewSegments = useFeature('new-company-size-segments').value ?? false;

  const handleChange: SelectInputProps<string>['onChange'] = (event) => {
    setValue('companySize', event.target.value);
    trigger('companySize');
    trackingUtils.trackEvent({
      event: 'Selected Company Size On Register',
      category: 'NonHomePage',
      properties: {
        companysize: event.target.value,
      },
    });
  };

  const companySizesToShow = showNewSegments ? newCompanySizes : companySizes;

  return (
    <FlexBoxV2
      width={ '100%' }
      column={ true }>
      <InputLabel
        htmlFor="select"
        sx={ inputLabelStyle(theme) }>
        Company Size
        <span
          style={{
            color: '#FF0000', // Red if new segments, default otherwise
            marginLeft: '4px',
          }}
        >
          *
        </span>
      </InputLabel>
      <FormControl>
        <Controller
          name={ nameInForm }
          defaultValue=""
          render={ ({ field }) => (
            <Select
              { ...field }
              id={ 'companySize' }
              key="companySize"
              name="companySize"
              variant="outlined"
              displayEmpty
              onChange={ handleChange }
              className={ styles.SignupFormV2__CompanySize }
              required
              error={ isTouched && !!error?.message }
              sx={ customStyles }
            >
              <MenuItem key="empty" value="" className="Mui-disabled">Choose a company size</MenuItem>
              { companySizesToShow.map((option) => (
                <MenuItem
                  key={ option.value }
                  value={ option.value }
                >
                  { option.label }
                </MenuItem>
              )) }
            </Select>
          ) }
        />
        { error?.message && isTouched &&
          <FormHelperText sx={{ marginTop: '-16px', marginLeft: '0', marginBottom: '18px' }} className="Mui-error">
            { error.message }
          </FormHelperText>
        }
      </FormControl>
    </FlexBoxV2>
  );
};
