import type { FC } from 'react';
import styled from '@emotion/styled';
import type { SbBlokData } from '@storyblok/js';
import { storyblokEditable } from '@storyblok/react';

import type { IconStoryblok } from '@/sb-types';


type IconBlokProps = {
  blok: SbBlokData<IconStoryblok>;
}

const svgRegex = /^<svg[\w\W]*<\/svg>$/;

const IconSvg = styled('span')<Omit<IconStoryblok, 'iconSvg'>>(({
  iconColor,
  marginObj,
  sizeObj,
  allowOriginalColor,
  theme,
}) => ({
  display: 'inline-flex',

  '& svg': {
    width: '100%',
    height: 'auto',

    '& path': {
      fill: allowOriginalColor ? '' : 'currentColor',
    },
  },

  ...marginObj,
  ...sizeObj,
  ...iconColor,
  ...theme.utils.objStyleBreakpoints(marginObj, sizeObj, iconColor),
}));

export const IconBlok: FC<IconBlokProps> = ({ blok: { iconSvg, ...allBlok }}) => {
  if (iconSvg == undefined) return null;
  if (!svgRegex.test(iconSvg)) return null;

  return (<IconSvg dangerouslySetInnerHTML={{ __html: iconSvg }} { ...allBlok } { ...storyblokEditable(allBlok) } />);
};
